import { TilesSelectorOption } from 'src/constants/types';
import { i18n } from 'src/locale';

import { BillingPeriod, Plan, RecurlyPlan, Role, RolesConfig } from './types';

export const INVOICES_QUERY_KEY = 'invoices';
export const PROMOTION_QUERY_KEY = 'promotion';
export const BILLING_INFO_QUERY_KEY = 'billing-information';
export const SUBSCRIPTION_INFO_QUERY_KEY = 'subscription-info';
export const SUBSCRIPTION_INFO_QUERY_KEY_INSTANT_CONVERSION = 'subscription-info-for-instant-conversion';

export const MIN_LICENCE_QUANTITY = 1;

export const BASIC_PLAN_MAX_LICENCE_QUANTITY = 99;
export const PRO_PLAN_MAX_LICENCE_QUANTITY = 30;

export const NORMAL_SUBSCRIPTION_QUANTITY_THRESHOLDS = [5, 20];
export const PRO_SUBSCRIPTION_QUANTITY_THRESHOLDS = [5, 10];

export const ANNUAL_PAYMENT_DISCOUNT_PERCENTAGE = 10;

export const ROLES_CONFIG: RolesConfig = {
  [Role.VETERINARY]: {
    [Plan.NORMAL]: {
      single: RecurlyPlan.VETERINARY_PROFESSIONAL_BASIC_INDIVIDUAL,
      multiple: RecurlyPlan.VETERINARY_PROFESSIONAL_BASIC_PRACTICE,
    },
    [Plan.PRO]: {
      single: {
        [BillingPeriod.MONTHLY]: RecurlyPlan.VETERINARY_PRO_INDIVIDUAL_MONTHLY,
        [BillingPeriod.ANNUAL]: RecurlyPlan.VETERINARY_PRO_INDIVIDUAL_ANNUAL,
      },
      multiple: {
        [BillingPeriod.MONTHLY]: RecurlyPlan.VETERINARY_PRO_PRACTICE_MONTHLY,
        [BillingPeriod.ANNUAL]: RecurlyPlan.VETERINARY_PRO_PRACTICE_ANNUAL,
      },
    },
  },
  [Role.VETERINARY_STUDENT]: {
    [Plan.PRO]: RecurlyPlan.VETERINARY_PRO_STUDENT_ANNUAL,
  },
  [Role.PHARMACIST]: {
    [Plan.NORMAL]: {
      single: RecurlyPlan.PHARMACY_BASIC_SINGLE,
      multiple: RecurlyPlan.PHARMACY_BASIC_MULTIPLE,
    },
  },
  [Role.PHARMACY_STUDENT]: {
    [Plan.NORMAL]: RecurlyPlan.PHARMACY_BASIC_STUDENT,
  },
};

export const STUDENT_ROLES: Role[] = [Role.PHARMACY_STUDENT, Role.VETERINARY_STUDENT];

export const DEFAULT_PLAN_VALUES = {
  role: Role.VETERINARY,
  plan: Plan.NORMAL,
  quantity: 1,
  discountCode: '',
  billingPeriod: BillingPeriod.ANNUAL,
} as const;

export const planSelectorOptions: TilesSelectorOption<Plan>[] = [
  {
    label: i18n.t('planVariants:normal'),
    value: Plan.NORMAL,
    testID: 'plan-normal-btn',
  },
  {
    label: i18n.t('planVariants:pro'),
    value: Plan.PRO,
    testID: 'plan-pro-btn',
  },
];

export const billingPlanSelectorOptions: TilesSelectorOption<BillingPeriod>[] = [
  {
    label: i18n.t('subscriptionProcess:payAnnually'),
    value: BillingPeriod.ANNUAL,
    description: i18n.t('subscriptionProcess:savePercent', { count: ANNUAL_PAYMENT_DISCOUNT_PERCENTAGE }),
    testID: 'pay-annually-btn',
  },
  {
    label: i18n.t('subscriptionProcess:payMonthly'),
    value: BillingPeriod.MONTHLY,
    testID: 'pay-monthly-btn',
  },
];

export const roleSelectorOptions: TilesSelectorOption<Role>[] = [
  {
    label: i18n.t('occupationVariants:vetPro'),
    value: Role.VETERINARY,
    testID: 'role-veterinary-btn',
  },
  {
    label: i18n.t('occupationVariants:vetStudent'),
    value: Role.VETERINARY_STUDENT,
    testID: 'role-veterinary-student-btn',
  },
  {
    label: i18n.t('occupationVariants:pharmacist'),
    value: Role.PHARMACIST,
    testID: 'role-pharmacist-btn',
  },
  {
    label: i18n.t('occupationVariants:pharmacyStudent'),
    value: Role.PHARMACY_STUDENT,
    testID: 'role-pharmacy-student-btn',
  },
];

export const STUDENT_DOC_LIMIT_EXCEEDED_CODE = 'doc_review_limit_exceeded';
