import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import {
  Animated,
  FlatList,
  LayoutAnimation,
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import { toggleAnimation } from 'src/animations/toggleAnimation';
import { EXPAND_ANIMATION_DURATION } from 'src/constants/constants';
import { AccordionItemParams as BaseItemParams, AccordionRef } from 'src/constants/types/accordion';
import { AccordionState, useAccordionState } from 'src/hooks/useAccordionState';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { ifWeb, palette, typography } from 'src/styles';

import { Icon } from './Icon/Icon';
import { MarkdownContent } from './MarkdownContent';
import { NumberIndicator } from './NumberIndicator';
import { Pressable } from './Pressable';

interface SharedProps {
  contentStyle?: StyleProp<ViewStyle>;
}

type AccordionItemParams = BaseItemParams & Pick<AccordionItemProps, 'itemRef' | 'dataSet'>;

interface AccordionProps {
  items: AccordionItemParams[];
  asFlatList?: boolean;
  listRef?: RefObject<FlatList<AccordionItemParams>>;
  shouldScrollToItemOnExpand?: boolean;
  onViewableItemsChanged?: React.ComponentProps<typeof FlatList>['onViewableItemsChanged'];
  onExpandedItemsChange?: (items: Record<string, boolean>) => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
  state?: AccordionState;
  beforeItemToggle?: (id: string, isExpanded: boolean) => Promise<boolean>;
  ListHeaderComponent?: React.ReactElement;
  ListFooterComponent?: React.ReactElement;
  testID?: string;
  fullWidthMode?: boolean;
  onItemOpen?(itemId: string): void;
  onItemClose?(itemId: string): void;
}

interface AccordionItemProps extends Pick<ViewProps, 'dataSet'> {
  toggleItem: (item: string) => void;
  expanded?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isAnimating: boolean;
  itemRef?: RefObject<View>;
  fullWidthMode?: boolean;
}

export const AccordionLegacy = forwardRef<AccordionRef, AccordionProps & SharedProps>(
  (
    {
      asFlatList,
      beforeItemToggle,
      contentContainerStyle,
      contentStyle,
      items,
      listRef,
      onExpandedItemsChange,
      onViewableItemsChanged,
      shouldScrollToItemOnExpand,
      state,
      ListFooterComponent,
      ListHeaderComponent,
      testID,
      fullWidthMode,
      onItemClose,
      onItemOpen,
    },
    ref,
  ) => {
    const internalState = useAccordionState(items);

    const { expandedItemsMap, setExpandedItemsMap, animatedItemsMap, setAnimatedItemsMap } =
      state ?? internalState;

    useImperativeHandle(ref, () => ({
      collapseAllItems,
      toggleItem,
      expandItem,
      isItemExpanded,
      openAllItems,
      areAllItemsCollapsed,
      areAllItemsExpanded,
    }));

    useEffect(() => {
      onExpandedItemsChange?.(expandedItemsMap);
    }, [expandedItemsMap, onExpandedItemsChange]);

    const animate = useCallback(
      (id: string) => {
        setAnimatedItemsMap((state) => ({
          ...state,
          [id]: true,
        }));
        LayoutAnimation.configureNext(toggleAnimation, () =>
          setAnimatedItemsMap((state) => ({
            ...state,
            [id]: true,
          })),
        );
      },
      [setAnimatedItemsMap],
    );

    const isItemExpanded = useCallback(
      (id: string) => {
        return !!expandedItemsMap[id];
      },
      [expandedItemsMap],
    );

    const areAllItemsCollapsed = useCallback(() => {
      return !items.find((item) => expandedItemsMap[item.id]);
    }, [items, expandedItemsMap]);

    const areAllItemsExpanded = useCallback(() => {
      return items.every((item) => expandedItemsMap[item.id]);
    }, [items, expandedItemsMap]);

    const expandItem = (id: string) => {
      const item = items.find((item) => item.id === id);

      if (item) {
        setExpandedItemsMap((state) => ({
          ...state,
          [id]: true,
        }));
        animate(id);
        setTimeout(() => {
          if (shouldScrollToItemOnExpand && listRef?.current) {
            listRef.current.scrollToItem({ animated: true, item });
          }
        }, 0);
      }
    };

    const toggleItem = async (id: string) => {
      const item = items.find((item) => item.id === id);

      if (!item) {
        return;
      }
      const isExpanded = expandedItemsMap[id];

      const shouldProceed = beforeItemToggle === undefined || (await beforeItemToggle(id, isExpanded));

      if (!shouldProceed) {
        return;
      }

      if (isExpanded) {
        onItemClose?.(id);
      } else {
        onItemOpen?.(id);
      }

      setExpandedItemsMap((state) => ({
        ...state,
        [id]: !state[id],
      }));
      animate(id);

      setTimeout(() => {
        if (shouldScrollToItemOnExpand && listRef?.current && !isExpanded) {
          listRef.current.scrollToItem({ animated: true, item });
        }
      }, 0);
    };

    const collapseAllItems = () => {
      setExpandedItemsMap(
        items.reduce(
          (obj, current) => ({
            ...obj,
            [current.id]: false,
          }),
          {},
        ),
      );
    };

    const openAllItems = useCallback(() => {
      setExpandedItemsMap(
        items.reduce(
          (obj, current) => ({
            ...obj,
            [current.id]: true,
          }),
          {},
        ),
      );
    }, [items, setExpandedItemsMap]);

    return (
      <>
        {asFlatList ? (
          <FlatList
            ref={listRef}
            data={items}
            contentContainerStyle={contentContainerStyle}
            scrollToOverflowEnabled
            renderItem={({ item, index }) => (
              <AccordionItem
                key={item.id}
                contentStyle={contentStyle}
                toggleItem={toggleItem}
                isFirst={index === 0}
                isLast={index === items.length - 1}
                expanded={expandedItemsMap[item.id]}
                isAnimating={animatedItemsMap[item.id]}
                fullWidthMode={fullWidthMode}
                {...item}
              />
            )}
            onViewableItemsChanged={onViewableItemsChanged}
            onScrollToIndexFailed={(info) => {
              setTimeout(() => {
                listRef?.current?.scrollToIndex({ index: info.index, animated: true });
              }, EXPAND_ANIMATION_DURATION);
            }}
            ListHeaderComponent={ListHeaderComponent}
            ListFooterComponent={ListFooterComponent}
          />
        ) : (
          <>
            {ListHeaderComponent}
            <View style={[contentContainerStyle]} testID={testID}>
              {items.map((item, index) => (
                <AccordionItem
                  key={item.id}
                  contentStyle={contentStyle}
                  toggleItem={toggleItem}
                  isFirst={index === 0}
                  isLast={index === items.length - 1}
                  expanded={expandedItemsMap[item.id]}
                  isAnimating={animatedItemsMap[item.id]}
                  fullWidthMode={fullWidthMode}
                  {...item}
                />
              ))}
            </View>
            {ListFooterComponent}
          </>
        )}
      </>
    );
  },
);

const AccordionItem: React.FC<AccordionItemParams & SharedProps & AccordionItemProps> = ({
  Content,
  Label,
  expanded,
  disabled,
  contentStyle,
  toggleItem,
  id,
  isLast,
  isFirst,
  isAnimating,
  itemRef,
  dataSet,
  fullWidthMode,
  numberIndicator,
}) => {
  const animationController = useRef(new Animated.Value(0)).current;
  const { isTablet } = useDeviceInfo();

  useLayoutEffect(() => {
    const config = {
      duration: EXPAND_ANIMATION_DURATION,
      toValue: expanded ? 1 : 0,
      useNativeDriver: true,
    };
    Animated.timing(animationController, config).start();
  }, [expanded, animationController]);

  const arrowTransform = animationController.interpolate({
    inputRange: [0, 1],
    outputRange: ['-180deg', '0deg'],
  });

  const handlePress = () => {
    if (disabled) {
      return;
    }
    toggleItem(id);
  };

  const labelElement =
    typeof Label === 'string' ? (
      <MarkdownContent inline style={styles.labelText} limited headerLevel={2}>
        {Label}
      </MarkdownContent>
    ) : (
      Label
    );

  const computedAccessibilityLabel = typeof Label === 'string' ? Label : undefined;

  return (
    <View
      dataSet={dataSet}
      ref={itemRef}
      style={[
        isAnimating && styles.accordionItemContainer,
        fullWidthMode && styles.itemContainerFullWidthMode,
      ]}
      testID="accordion-wrapper"
    >
      <Pressable onPress={handlePress} accessibilityLabel={computedAccessibilityLabel}>
        {(isHovered, isPressed) => (
          <View
            style={[
              styles.label,
              styles.itemBorderColor,
              styles.itemSideBorders,
              styles.itemBottomBorder,
              isFirst && [styles.itemTopBorder, isTablet && styles.itemRoundedTopBorder],
              isLast && isTablet && !expanded && styles.itemRoundedBottomBorder,
              (isHovered || isPressed) && styles.labelActive,
            ]}
          >
            <View
              style={styles.labelTextWrapper}
              testID="accordion-title"
              dataSet={{ isExpanded: !!expanded }}
            >
              {labelElement}
              {!!numberIndicator && (
                <NumberIndicator
                  text={numberIndicator}
                  style={styles.numberIndicator}
                  testID="new-items-amount-badge"
                />
              )}
            </View>
            <Animated.View style={{ transform: [{ rotate: arrowTransform }] }}>
              <Icon name="chevron-up" width={10} color={disabled ? palette.grey4 : palette.navy} />
            </Animated.View>
          </View>
        )}
      </Pressable>
      {expanded && (
        <View
          style={[styles.itemSideBorders, styles.itemBorderColor, styles.itemBottomBorder, contentStyle]}
        >
          {Content}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    backgroundColor: palette.grey0,
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none',
    ...ifWeb({
      transitionProperty: 'background-color',
    }),
  },
  labelActive: {
    backgroundColor: palette.grey2,
  },
  labelTextWrapper: {
    flex: 1,
    paddingRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    ...typography.body3,
    color: palette.navy,
  },
  numberIndicator: {
    marginLeft: 10,
  },
  itemBorderColor: {
    borderColor: palette.grey2,
  },
  itemSideBorders: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  itemBottomBorder: {
    borderBottomWidth: 1,
  },
  itemTopBorder: {
    borderTopWidth: 1,
  },
  itemRoundedTopBorder: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  itemRoundedBottomBorder: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  itemContainerFullWidthMode: {
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  accordionItemContainer: {
    overflow: 'hidden',
  },
});
